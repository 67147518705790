.banner {
  text-align: center;
  background-color: #DD0060;
  color: white;
}

.routerOutletMargin {
  margin: 10px;
}

.mindshineLogo{
  width: 180px;
  padding: auto;
  display: block;
  margin: 20px auto 40px;
}

.displayInline{
  display: inline-block;
}

.mindshineLogoContainer{
  width: 90%;
  position: relative;
  left: 0;
  right: 0;
  top: 10%;
  bottom: 0;
  margin: 2em auto auto auto;
  max-height: 100%;
  min-width: 300px;
  overflow: auto;
}

.progressBarContainer {
  margin: 15px auto 25px;
  max-width: 734px;
  text-align: center;
  width: 100%;
}

.progressBarCustom{
  height: 0.5em;
  padding: 0 0 0 0!important;
  margin: 0 1px 0 1px;
  .progress-bar {
    background-color: #166FFF;
  }
}

.logoutButton{
  position: absolute;
  margin: 20px auto;
  top: 0;
  right: 0;
}

.progressText{
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 17px;
  text-align: center;
}

.preLine {
  white-space: pre-line;
}
