/* You can add global styles to this file, and also import other style files */
* {
  font-family: Nunito
}

.fakeLink{
  color: #166FFF!important;
  text-decoration: none!important;
  background-color: transparent;
}

.fakeLink:hover{
  color: #166FFF!important;
  text-decoration: underline!important;
  cursor: pointer;
}


.discountedPrice {
  text-decoration: line-through;
  color: #166FFF;
}


.discountCode{
  text-decoration: underline;
}

.discountBanner{
  width: 74%;
  margin: auto;
}

.messagesContainer {
  margin: auto;
  min-width: 200px;
  width: 60%;
}

.autoMargin {
  margin: auto;
}

.dividerSpacing {
  margin-bottom: 1rem;
}

.dividerText {
  margin-top: 0.3rem;
}

.centerContent {
  /*position: absolute; !*it can be fixed too*!*/
  left: 0;
  right: 0;
  top: 10%;
  bottom: 0;
  margin: auto;

  /*to solve "the content will not be cut when the window is smaller than the content": */
  max-width: 400px;
  max-height: 100%;
  overflow: unset;
}

.form-container {
  max-width: 640px;
  width: 85%;
}

.textAlignCenter {
  text-align: center;
  margin-bottom: 5%;
}

.agb {
  color: #898989;
  font-size: 16px;
}

.agbForm {
  width: 100%;
}

.marginOverride {
  margin: unset !important;
}

.inlineBlock {
  display: inline-block;
}

.floatLeft {
  float: left;
}

.invisible {
  display: none;
}

.breadcrumb-item {
  display: inline-block;
  width: 100px !important;
}

/* Override for ng2 breadcrumbs */

.breadcrumb {
  margin-left: 1%;
}

.breadcrumb-item.active {
  font-weight: bold;
}

.breadcrumb-item a {
  color: #DD0060;
  text-decoration: none;
}

.breadcrumb-item a:after {
  position: absolute; /* Prevent underline of arrow */
  padding-left: 10px; /* Add a little space between text and arrow */
  content: "\00bb"; /* Unicode hex for &raquo; */
}

.font-grey {
  color: #aaaaaa;
}

.bold {
  font-weight: bold;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:active,
.btn-dark:visited,
.btn-dark:focus {
    background-color: #040435;
    border-color: #040435;
}

.loading {
  opacity: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: #272c33;
  padding-top: 25vh;
  text-align: center;
  z-index: -1;
}

.alert-primary {
  background-color: #ECF2FA!important;
  border-color: #166FFF!important;
  color: #166FFF!important;
}

.fas.text-primary {
  color: #166FFF!important;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
