.downloadBadgeAppStore{
  width: 220px;
  margin-top: 1.11em;
}

.downloadBadgePlayStore{
  width: 280px;
}

.congratulationsLogo{
  width: 200px;
  height: 200px;
}
