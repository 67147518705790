.language-switcher {
  padding-bottom: 2rem  ;

  .language:not(:last-child):after {
    content: " | ";
    color: #dd0060 !important;
    margin-bottom: 0.5rem;
    cursor: default !important;
  }
  .language:not(:first-child) {
    padding-left: 0.2rem;
  }
  .language {
    color: #dd0060;
    text-decoration: unset !important;
    transition: color 0.15s;
  }
  .language.selected {
    color: #91003f !important;
    cursor: default !important;
  }
  .language:hover {
    color: #91003f !important;
    cursor: pointer;
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  outline: none;
  text-decoration: none;
}
